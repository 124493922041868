<template>
  <v-container
    class="container-bg rounded elevation-3"
    fluid>
    <v-form
      ref="vendor-form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col cols="12">
          <h2>จัดการ Vendor</h2>
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูลผู้ขาย
          </span>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.name"
            label="ชื่อ"
            color="secondary"
            :rules="[ ...textBoxRules, maxLengthRule(100) ]"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.name2"
            label="ชื่อ 2"
            :rules="[ maxLengthRule(50) ]"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.searchName"
            :rules="[ maxLengthRule(100) ]"
            label="ชื่อสำหรับค้นหา"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.taxRegistrationNumber"
            label="รหัสประจำตัวผู้เสียภาษี"
            color="secondary"
            :rules="[ ...textBoxRules, maxLengthRule(20) ]"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.phoneNumber"
            :rules="[ maxLengthRule(30) ]"
            label="เบอร์โทร"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.email"
            :rules="[ maxLengthRule(80) ]"
            label="อีเมล"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.contactName"
            label="ชื่อผู้ติดต่อ"
            :rules="[ maxLengthRule(100) ]"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.purchaserCode"
            label="รหัสผู้จัดซื้อ"
            :rules="[ maxLengthRule(20) ]"
            color="secondary"
            :disabled="true"
            dense
            outlined />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-textarea
            v-model="formData.address"
            label="ที่อยู่"
            color="secondary"
            :rules="[ ...textBoxRules, maxLengthRule(100) ]"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-textarea
            v-model="formData.address2"
            :rules="[ maxLengthRule(50) ]"
            label="ที่อยู่ (เพิ่มเติม)"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.city"
            :rules="[ maxLengthRule(30) ]"
            label="อำเภอ / เขต"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.state"
            :rules="[ maxLengthRule(30) ]"
            label="จังหวัด / เมือง"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-autocomplete
            v-model="formData.countryLetterCode"
            :rules="[ maxLengthRule(10) ]"
            label="ประเทศ"
            color="secondary"
            :items="shippingCountries"
            item-text="label"
            item-value="code"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.postalCode"
            label="รหัสไปรษณีย์"
            :rules="[ maxLengthRule(20) ]"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.homePage"
            label="โฮมเพจ"
            :rules="[ maxLengthRule(80) ]"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-text-field
            v-model="formData.branch"
            :rules="[
              ...branchRules,
              maxLengthRule(50)
            ]"
            label="สาขา"
            color="secondary"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.vendorType"
            label="ประเภท"
            color="secondary"
            :items="vendorTypes"
            item-text="text"
            item-value="value"
            dense
            outlined
            :disabled="loading || isEdit" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.blocked"
            label="ไม่ให้ใช้งาน"
            color="secondary"
            :items="blockedTypes"
            item-text="text"
            item-value="value"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          class="header-line">
          <span>
            ข้อมูลภาษี
          </span>
          <v-divider />
        </v-col>
        <!-- <v-col
          cols="12"
          md="3"
          class="switch-container">
          <v-switch
            v-model="formData.priceIncludesTax"
            label="ราคารวม VAT"
            color="secondary"
            :disabled="loading" />
        </v-col> -->
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.paymentTermCode"
            label="Payment Term Code"
            color="secondary"
            :items="paymentTermCodes"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.currencyCode"
            label="รหัสสกุลเงิน"
            color="secondary"
            placeholder="ถ้าเป็น THB ไม่ต้องส่งค่า"
            :items="currencies"
            item-text="text"
            item-value="value"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.vendorPostingGroup"
            label="กลุ่ม Vendor"
            color="secondary"
            :items="vendorGroup"
            item-text="text"
            item-value="value"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.genBusPostingGroup"
            label="กลุ่ม Gen. Bus."
            color="secondary"
            :items="vendorGroup"
            item-text="text"
            item-value="value"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.vatBusPostingGroup"
            label="ประเภท VAT"
            color="secondary"
            :items="vendorGroup"
            item-text="text"
            item-value="value"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <v-select
            v-model="formData.whtBusPostingGroup"
            label="การหักภาษี ณ ที่จ่าย"
            color="secondary"
            :items="vatGroup"
            item-text="text"
            item-value="value"
            dense
            outlined
            :disabled="loading" />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center align-center">
          <v-btn
            class="px-8"
            :loading="loading"
            color="success"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ShippingCountries from '@/assets/json/ShippingCountries.json'
import VendorsProvider from '@/resources/VendorsProvider'

const VendorsService = new VendorsProvider()

export default {
  data () {
    return {
      loading: false,
      isEdit: false,
      textBoxRules: [
        (v) => !!v || 'โปรดกรอก Field นี้'
      ],
      paymentTermCodes: [
        '0D',
        '7D',
        '15D',
        '30D',
        '45D',
        '60D'
      ],
      currencies: [
        { value: '', text: 'THB' },
        { value: 'CNY-S', text: 'CNY' },
        // { value: 'EUR-S', text: 'EUR' },
        // { value: 'SGD-S', text: 'SGD' },
        { value: 'USD-S', text: 'USD' }
      ],
      shippingCountries: ShippingCountries,
      formData: {
        name: '',
        name2: '',
        searchName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        countryLetterCode: '',
        postalCode: '',
        phoneNumber: '',
        email: '',
        homePage: '',
        taxRegistrationNumber: '',
        branch: '',
        priceIncludesTax: false,
        currencyCode: '',
        blocked: '',
        vendorType: 'juristic',
        paymentTermCode: '7D',
        vendorPostingGroup: 'DOMESTIC',
        contactName: '',
        purchaserCode: '',
        genBusPostingGroup: 'DOMESTIC',
        vatBusPostingGroup: 'DOMESTIC',
        whtBusPostingGroup: 'PND53'
      },
      vendorTypes: [
        {
          text: 'นิติบุคคล',
          value: 'juristic'
        },
        {
          text: 'บุคคลธรรมดา',
          value: 'individual'
        }
      ],
      paymentTermUnits: [
        {
          text: 'DAY',
          value: 'D'
        },
        {
          text: 'WEEK',
          value: 'W'
        },
        {
          text: 'MONTH',
          value: 'M'
        },
        {
          text: 'YEAR',
          value: 'Y'
        }
      ],
      vendorGroup: [
        {
          text: 'ภายในประเทศ',
          value: 'DOMESTIC'
        },
        {
          text: 'ต่างประเทศ',
          value: 'FOREIGN'
        }
      ],
      vatGroup: [
        {
          text: 'PND53',
          value: 'PND53'
        },
        {
          text: 'PND3',
          value: 'PND3'
        },
        {
          text: 'NOWHT',
          value: 'NOWHT'
        }
      ],
      blockedTypes: [
        {
          text: 'None',
          value: ''
        },
        {
          text: 'Payment',
          value: 'Payment'
        },
        {
          text: 'All',
          value: 'All'
        }
      ]
    }
  },
  computed: {
    branchRules () {
      if (this.formData.vendorType === 'individual') {
        return []
      }

      return this.textBoxRules
    }
  },
  mounted () {
    if (this.$route.params?.id) {
      this.isEdit = true
      this.getItem()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    maxLengthRule (length) {
      return (v) => v.length <= length || `Field นี้ต้องยาวไม่เกิน ${length} ตัวอักษร`
    },
    async getItem () {
      try {
        this.loading = true

        const { data } = await VendorsService.getItemById(this.$route.params.id)

        this.formData = {
          ...data,
          vendorType: data.vendorType.name
        }
      } catch (error) {
        console.error('getItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      const validated = await this.$refs['vendor-form'].validate()

      if (!validated) {
        return
      }

      try {
        this.loading = true

        if (this.isEdit) {
          await VendorsService.updateItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'แก้ไข Vendor สำเร็จ',
            type: 'success'
          })
        } else {
          await VendorsService.createItem(this.formData)
          this.setSnackbar({
            value: true,
            message: 'สร้าง Vendor สำเร็จ',
            type: 'success'
          })
        }

        this.$router.push({ name: 'PurchasesVendors' })
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
.header-line {
  position: relative;
}
.header-line span {
  font-weight: 500;
  background-color: white;
  padding-right: 8px;
  position: absolute;
  left: 12px;
  top: 0;
}
</style>

<style>
.switch-container .v-input--selection-controls {
  margin-top: 5px !important;
}
</style>
